import React from "react";

function DownloadResumeButton() {
  const handleDownload = () => {
    window.open(process.env.PUBLIC_URL + "/resume.pdf", "_blank");
  };

  return (
    <button className="btn-main-offer" onClick={handleDownload}>
      Resume
    </button>
  );
}

export default DownloadResumeButton;
