import React from "react";
import Typed from "react-typed";
import ExampleModal from "./ContactModal";
import DownloadResumeButton from "./Downloadbutton";

const Header = () => {
  return (
    <div className="header-wrapper">
      <div className="main-info">
        <h1>Software Development</h1>
        <Typed
          className="typed-text"
          strings={["Java", "Java Spring", "C++", "JavaScript", "React", "Python"]}
          typeSpeed={40}
          backSpeed={50}
          loop
        />
        <div className="buttons-wrapper">
          <div>
            <ExampleModal />
          </div>
          <div>
            <DownloadResumeButton />
          </div>
        </div>

        <div className="down-arrow" />
      </div>
    </div>
  );
};

export default Header;
