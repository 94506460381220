export const imgObjs = [
  {
    url: "/images/skills_svgs/java.svg",
    name: "Java",
  },
  {
    url: "/images/skills_svgs/python.svg",
    name: "python",
  },
  {
    url: "/images/skills_svgs/js.svg",
    name: "JavaScript",
  },
  {
    url: "/images/skills_svgs/spring.svg",
    name: "Java Spring",
  },
  {
    url: "/images/skills_svgs/cpp.svg",
    name: "C++",
  },
  {
    url: "/images/skills_svgs/react.svg",
    name: "React",
  },
  {
    url: "/images/skills_svgs/sql.svg",
    name: "SQL",
  },
  {
    url: "/images/skills_svgs/mongodb.svg",
    name: "MongoDB",
  },
  {
    url: "/images/skills_svgs/docker.svg",
    name: "Docker",
  },
];
