import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";

export default function ContactModal(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm("service_49u09sc", "template_5qi3iot", form.current, "EqHxnyRcj6tatFiTB").then(
      (result) => {
        console.log(result.text);
        e.target.reset();
        handleClose();
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  return (
    <>
      <button className="btn-main-offer" onClick={handleShow}>
        Contact Me
      </button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Contact Me</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StyledContactForm>
            <form ref={form} onSubmit={sendEmail} id="contact">
              <label>Name</label>
              <input type="text" name="user_name" />
              <label>Your Email</label>
              <input type="email" name="user_email" />
              <label>Message</label>
              <textarea name="message" />
              <input type="submit" value="Send" />
            </form>
          </StyledContactForm>
        </Modal.Body>
      </Modal>
    </>
  );
}

const StyledContactForm = styled.div`
  width: 470px;
  form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 16px;
    input {
      width: 100%;
      height: 35px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);
      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }
    textarea {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      max-height: 100px;
      min-height: 100px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);
      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }
    label {
      margin-top: 1rem;
    }
    input[type="submit"] {
      margin-top: 2rem;
      cursor: pointer;
      background: rgb(249, 105, 14);
      color: white;
      border: none;
    }
  }

  @media (max-width: 500px) {
    width: 325px;
  }
`;
