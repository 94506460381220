import React from "react";

const Experience = () => {
  return (
    <>
      <section id="experience">
        <div className="experience bg-dark">
          <div className="d-flex justify-content-center my-5">
            <h1>Experience & Timeline</h1>
          </div>
          <div className="container experience-wrapper">
            {/*  */}
            <div className="timeline-block timeline-block-right">
              <div className="marker"></div>
              <div className="timeline-content">
                <h3>Feb - April 2023</h3>
                <p>
                  <b>Research Internship - IHMC</b>
                </p>
                <p>
                  As a research intern at the Institute for Human and Machine Cognition (IHMC) in
                  Pensacola, FL, I collaborated with a team to develop a research project. We
                  focused on backend APIs and data analysis. Python and the Flask framework were
                  employed to create the APIs, which facilitated the execution of experiments. For
                  data science purposes, the PANDAS library was utilized to analyze data and
                  generate visualizations.
                </p>
                <p>
                  The team extensively employed Bash and Python scripting to automate experimental
                  procedures and streamline the installation of required dependencies as well as
                  using GitLab for version control.
                </p>
              </div>
            </div>
            <div className="timeline-block timeline-block-left">
              <div className="marker"></div>
              <div className="timeline-content">
                <h3>April 2023 - Now</h3>
                <p>
                  <b>Year-Round Digital Intern - NFCU</b>
                </p>
                <p>
                  As a Digital Intern at <strong>Navy Federal Credit Union</strong> (NFCU), I have
                  the opportunity to be part of a dynamic and innovative team. Our primary focus
                  revolves around the utilization of Java and the Java Spring (Boot) Framework. This
                  allows us to efficiently develop robust and scalable backend APIs, which are
                  crucial components in our organization's microservices ecosystem.
                </p>
                <p>
                  In addition to Java and Spring Boot, I have also had hands-on experience with
                  cloud infrastructure and container orchestration, specifically leveraging{" "}
                  <strong>Microsoft Azure</strong>. Our team employs <strong>Docker</strong> to
                  containerize our applications, ensuring consistency and scalability across
                  different environments. Using <strong>Kubernetes</strong> for orchestration, we
                  are able to automatically deploy, scale, and manage these containers. This
                  combination of technologies allows for a more resilient and flexible architecture,
                  enabling us to respond faster to changes and provide more reliable services to
                  NFCU members.
                </p>
                <p>
                  Working in an Agile environment, we continuously strive to adapt and improve upon
                  our processes and deliverables. Our team collaboration ensures rapid iteration and
                  integration of feedback, resulting in high-quality digital solutions for our
                  valued NFCU members. With this experience, I am constantly learning and growing as
                  a professional, while making a meaningful impact on the financial well-being of
                  our members.
                </p>
              </div>
            </div>
            <div className="timeline-block timeline-block-right">
              <div className="marker"></div>
              <div className="timeline-content">
                <h3>Spring 2023</h3>
                <p>
                  <b>Dean's List | GPA: TBD</b>
                </p>
                <p></p>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Experience;
