export const status = {
  IN_PROGRESS: "In-Progress",
  COMPLETE: "Complete",
  UPDATING: "Updating",
};

export const projects = [
  {
    projectName: "Grade Calculator",
    websiteURL: "https://grade-calculator-uwf.web.app/courses",
    githubURL: "https://github.com/KodyAnderson1/GradeTracker",
    stack: ["React", "JavaScript", "NoSQL"],
    imagePath: "/images/project_imgs/grade-calculator.png",
    status: status.COMPLETE,
  },
  {
    projectName: "Portfolio Website",
    websiteURL: "https://kanderson.dev/",
    githubURL: "https://github.com/KodyAnderson1/PortfolioWebsite",
    imagePath: "/images/project_imgs/portfolio-website.png",
    stack: ["Bootstrap", "React", "JavaScript"],
    status: status.COMPLETE,
  },
  {
    projectName: "Job/Application Tracker",
    githubURL: "https://github.com/KodyAnderson1/ApplicationTracker",
    imagePath: "/images/project_imgs/updatedAppTracker.png",
    stack: ["React", "Express", "MongoDB"],
    status: status.IN_PROGRESS,
  },
  {
    projectName: "Student Management Full Stack",
    githubURL: "https://github.com/KodyAnderson1/StudentDatabaseFullStack",
    imagePath: "/images/project_imgs/student_full_stack.png",
    stack: ["React", "Java", "MySQL"],
    status: status.IN_PROGRESS,
  },
  {
    projectName: "Taskify",
    websiteURL: "https://taskify-a2209.web.app/",
    githubURL: "https://github.com/KodyAnderson1/taskify",
    imagePath: "/images/project_imgs/taskify.png",
    stack: ["Bootstrap", "TypeScript", "React"],
    status: status.COMPLETE,
  },
  {
    projectName: "Notes App",
    websiteURL: "https://notesapp-c9688.web.app/",
    githubURL: "https://github.com/KodyAnderson1/MarkdownNotesApp",
    imagePath: "/images/project_imgs/Notes_markdown_app.png",
    stack: ["CSS", "TypeScript", "React"],
    status: status.COMPLETE,
  },
  {
    projectName: "Sudoku",
    githubURL: "https://github.com/KodyAnderson1/SudokuDesktopJava",
    imagePath: "/images/project_imgs/java_sudoku.png",
    stack: ["Java"],
    status: status.COMPLETE,
  },
  {
    projectName: "Train Reservation System",
    githubURL: "https://github.com/KodyAnderson1/TrainReservationSystem",
    imagePath: "/images/project_imgs/train-reservations.png",
    stack: ["Java"],
    status: status.COMPLETE,
  },
];
