import React from "react";

const AboutMe = () => {
  return (
    <section id="about">
      <div className="container p-3 mb-5">
        <div className="row">
          <div className="col-lg-6 col-xm-12">
            <div className="photo-wrapper">
              <img className="profile-img" src="/images/headshot.jpg" alt="author's headshot" />
            </div>
          </div>
          <div className="col-lg-6 col-xm-12">
            <h2 className="about-heading py-4">About Me</h2>
            <p>
              My name is Kody Anderson. I'm a senior at The University of West Florida (UWF) set to
              graduate in December 2023. My journey in Software Design & Development, complemented
              by a minor in Computer Science and a Cybersecurity certificate, has culminated in an
              overall UWF GPA of <b>3.73</b> and a notable major GPA of <b>3.90</b>.
            </p>
            <p>
              My hands-on experience has been enriched with the latest tech stacks. During my time
              as a Research Intern at the Institute for Human and Machine Cognition (IHMC) in
              Pensacola, I've dealt extensively with backend APIs, utilizing Python, Flask, and the
              PANDAS library for data analysis and visualization. My toolkit also features extensive
              Bash and Python scripting capabilities, enhanced by version control using GitLab.
            </p>
            <p>
              As of April 2023, I've embarked on a new venture as a Year-Round Digital Intern at{" "}
              <b>Navy Federal Credit Union</b>. Here, I'm delving deep into backend development with{" "}
              <b>Java and the Java Spring (Boot) Framework</b>. Furthermore, I've gained practical
              experience with cloud solutions like <strong>Microsoft Azure</strong>, and container
              technologies such as <strong>Docker</strong> and <strong>Kubernetes</strong>, ensuring
              our software solutions are scalable and resilient.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
